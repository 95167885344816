<template>
  <PageWithLayout :isBodyBg="false">
    <div class="group_box">
      <BoxView title="tab" :grid="100">
        <TabComponent
          :tabData="tabData"
          :value.sync="tabData.value"
          @update:value="value => onUpdateTab(value)"/>
      </BoxView>
      <BoxView title="tab count" :grid="100">
        <TabComponent
          :tabData="tabCountData"
          :value.sync="tabCountData.value"
          @update:value="value => onUpdateTab(value)"/>
      </BoxView>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import TabComponent from '@lemontree-ai/lemontree-admin-common-front/components/common/tab/Tab';

export default {
  name:'Tab',
  components:{
    PageWithLayout,
    BoxView,
    TabComponent
  },
  data(){
    return{
      tabData:{
        dataList: [
          { id: 'tab1', text:'tab1' },
          { id: 'tab2', text:'tab2' },
        ],
        option:{
          isCount: false,
          queryKeyName: 'tabValue'
        },
        value: 'tab1'
      },
      tabCountData:{
        dataList: [
          { id: 'tab1', text:'tab1', count:3 },
          { id: 'tab2', text:'tab2', count:5 },
          { id: 'tab3', text:'tab3', count:0 },
        ],
        option:{
          isCount: true,
          queryKeyName: 'tabCountValue'
        },
        value: 'tab1'
      }
    }
  },
  methods:{
    onUpdateTab(value){
      console.log(value)
    }
  }
}
</script>

<style scoped>
</style>